<script lang="ts" setup>
const user = useUser();

const contentConfig = useContentConfig();

const loadedContent = useLoadedContent();

const isDark = computed(() => loadedContent.value?.fields.slug === "home");

const searchOpen = ref(false);
const burgerOpen = ref(false);
const loginOpen = ref(false);
const userMenuOpen = ref(false);
const route = useRoute();

watch(route, () => (burgerOpen.value = false));

const searchInput = ref<HTMLInputElement | undefined>();

const searchFocus = (event: Event) => {
  event.preventDefault();

  searchOpen.value = true;

  setTimeout(() => searchInput.value?.focus(), 300);
};

const search = async (event: Event) => {
  event.preventDefault();

  if (searchInput.value?.value) {
    await navigateTo(
      `/search?q=${encodeURIComponent(searchInput.value.value)}`,
    );

    searchOpen.value = false;
  }
};

const logout = (event: Event) => {
  event.preventDefault();

  userMenuOpen.value = false;

  if (useUser().value) {
    $fetch("/api/auth/logout", { method: "POST" }).then(() => {
      useUser().value = null;
    });
  }
};

const onBurgerClick = () => {
  burgerOpen.value = !burgerOpen.value;
  if (burgerOpen.value && loginOpen.value) loginOpen.value = false;
  userMenuOpen.value = false;
};

const onAccountClick = () => {
  loginOpen.value = !loginOpen.value;
  if (burgerOpen.value && loginOpen.value) burgerOpen.value = false;
};

const onUserClick = () => {
  userMenuOpen.value = !userMenuOpen.value;
  if (burgerOpen.value && userMenuOpen.value) burgerOpen.value = false;
};

const onLoginClickOutside = (event: Event) => {
  if ((event.target as HTMLElement).id !== "acc_button")
    loginOpen.value = false;
};
</script>

<template>
  <Container
    class="header md:block"
    :class="{ dark: isDark }"
  >
    <header
      class="!z-[100] flex items-center justify-between pt-2 max-lg:pb-2 lg:pt-[20px]"
    >
      <div class="flex items-center">
        <NuxtLink
          to="/"
          class="w-[88px]"
          aria-label="Home"
        >
          <NuxtIcon
            name="logo"
            class="logo w-[88px]"
            :class="isDark ? 'text-white' : 'text-dark-blue-grey-two'"
          />
        </NuxtLink>
        <div class="ml-4 leading-5">
          <div class="text-xs max-md:-mt-px lg:mb-[-3px] lg:mt-[2px]">
            Member AUM
          </div>
          <span class="text-lg font-medium lg:text-xl">${{ contentConfig?.aum }} trillion</span>
        </div>
      </div>
      <div class="search relative hidden items-center lg:flex">
        <NuxtIcon
          class="absolute px-2"
          :class="isDark ? 'text-white' : 'text-dark-blue-grey-two'"
          name="search"
        />
        <form
          action="/search"
          method="get"
        >
          <input
            type="search"
            name="q"
            placeholder="Search FAIRR"
            class="w-[422px] pl-[2.5rem]"
            @click="searchFocus"
          />
        </form>
        <!-- Lazy load the modal component to prevent it pulling Vimeo libs into entry bundle -->
        <LazyOtherModal
          v-model="searchOpen"
          class="items-start"
        >
          <Container class="w-screen bg-white py-5">
            <div class="relative flex items-center">
              <NuxtIcon
                class="absolute px-2"
                name="search"
              />
              <form
                class="flex w-full gap-3"
                @submit="search"
              >
                <input
                  ref="searchInput"
                  type="search"
                  placeholder="Search for a topic, page, news item…"
                  class="w-[422px] pl-[2.5rem]"
                />
                <button class="btn">
                  Submit
                </button>
              </form>
            </div>
            <div
              v-if="contentConfig?.suggestedSearches?.length"
              class="flex flex-wrap gap-4 pt-4"
            >
              <div class="w-full text-xs font-semibold">
                Suggested Searches
              </div>
              <NuxtLink
                v-for="(term, index) in contentConfig.suggestedSearches"
                :key="index"
                class="text-tag rounded bg-level-standard p-1"
                :to="`/search?q=${encodeURIComponent(term)}`"
                @click="() => (searchOpen = false)"
              >
                {{ term }}
              </NuxtLink>
            </div>
          </Container>
        </LazyOtherModal>
      </div>
      <div class="relative flex items-center gap-2">
        <button
          v-if="!user"
          id="acc_button"
          class="size-[40px] rounded-sm bg-fairr-light-blurple focus:outline-none sm:hidden"
          aria-label="Open login menu"
          @click.stop="onAccountClick"
        >
          <NuxtIcon
            name="account"
            class="size-[30px] text-dark-blue-grey-two"
          />
        </button>

        <button
          v-if="user"
          class="flex items-center gap-2 rounded border-ui-grey md:w-[230px] md:border md:px-2 md:py-1"
          aria-label="Open account menu"
          @click="onUserClick"
        >
          <div
            class="flex size-[40px] items-center justify-center rounded-sm bg-fairr-light-blurple md:size-[32px] md:rounded-full lg:size-[36px]"
          >
            <NuxtIcon
              name="account"
              class="size-[30px] text-dark-blue-grey-two"
            />
          </div>
          <div class="grow text-left max-md:hidden">
            {{ user.salesforce.contact.firstName || "" }}
            {{ user.salesforce.contact.lastName || "" }}
          </div>
          <NuxtIcon
            name="arrow"
            class="max-md:!hidden"
            :class="{ 'rotate-180': userMenuOpen }"
          />
        </button>

        <div
          class="user-menu absolute right-0 top-[52px] z-[51] w-[230px] rounded-sm border border-ui-grey bg-white p-3 text-dark-blue-grey-two shadow-2xl sm:right-[55px] lg:right-[7px]"
          :class="!userMenuOpen && 'hidden'"
        >
          <NuxtLink
            v-if="false"
            class="mb-3 flex items-center"
            to="/dashboard"
          >
            <div
              class="ai mr-2 size-[36px] rounded-sm bg-fairr-light-blurple p-[5px]"
            >
              <NuxtIcon name="dashboard" />
            </div>
            Dashboard
          </NuxtLink>

          <NuxtLink
            v-if="false"
            class="mb-3 flex items-center"
            to="/bookmarks"
          >
            <div
              class="ai mr-2 size-[36px] rounded-sm bg-fairr-light-blurple p-[5px]"
            >
              <NuxtIcon name="bookmark" />
            </div>
            Bookmarks
          </NuxtLink>

          <a
            class="flex items-center"
            href=""
            @click="logout"
          >
            <div
              class="ai mr-2 size-[36px] rounded-sm bg-fairr-light-blurple p-[5px]"
            >
              <NuxtIcon
                name="logout"
                filled
              />
            </div>
            Logout
          </a>
        </div>

        <div
          v-if="!user"
          v-click-outside="onLoginClickOutside"
          class="login-menu -right-3 top-5 border-ui-grey2 max-sm:absolute max-sm:z-50 max-sm:min-w-[375px] max-sm:border-t max-sm:bg-white max-sm:p-3"
          :class="
            !loginOpen && 'opacity-0 max-sm:pointer-events-none sm:opacity-100'
          "
        >
          <div class="gap-2 sm:flex">
            <NuxtLink
              class="btn max-sm:mb-3 max-sm:w-full"
              to="/login"
              @click="() => (loginOpen = false)"
            >
              Login
            </NuxtLink>
            <NuxtLink
              class="btn max-sm:w-full"
              to="/signup"
              @click="() => (loginOpen = false)"
            >
              Sign Up
            </NuxtLink>
          </div>
        </div>

        <button
          id="burger"
          class="relative size-[40px] rounded-sm bg-fairr-light-blurple focus:outline-none lg:hidden"
          aria-label="Open main menu"
          @click.stop="onBurgerClick"
        >
          <div
            class="pointer-events-none absolute left-1/2 top-1/2 block size-full -translate-x-1/2 -translate-y-1/2"
          >
            <span
              aria-hidden="true"
              class="absolute left-1/2 -mt-px block h-0.5 w-[20px] -translate-x-1/2 bg-dark-blue-grey-two transition duration-500 ease-in-out"
              :class="{
                'translate-y-[20px] rotate-45': burgerOpen,
                ' translate-y-[12px]': !burgerOpen,
              }"
            />
            <span
              aria-hidden="true"
              class="absolute left-1/2 -mt-px block h-0.5 w-[20px] -translate-x-1/2 translate-y-[20px] bg-dark-blue-grey-two transition duration-500 ease-in-out"
              :class="{ 'opacity-0': burgerOpen }"
            />
            <span
              aria-hidden="true"
              class="absolute left-1/2 -mt-px block h-0.5 w-[20px] -translate-x-1/2 bg-dark-blue-grey-two transition duration-500 ease-in-out"
              :class="{
                'translate-y-[20px] -rotate-45': burgerOpen,
                ' translate-y-[28px]': !burgerOpen,
              }"
            />
          </div>
        </button>
      </div>
    </header>
    <NavigationMain
      v-if="contentConfig?.mainNavigation"
      v-bind="contentConfig?.mainNavigation"
      :open="burgerOpen"
      @on-click-outside="() => (burgerOpen = false)"
      @search="searchFocus"
    />
  </Container>
</template>

<style lang="scss" scoped>
.ai:deep(svg) {
  width: 26px;
  height: 26px;

  polygon {
    @apply fill-none;
  }
}

.header {
  @apply border-b border-b-pale-lilac-two;

  &.dark {
    @apply relative z-10 border-b-white/20;

    header {
      @apply text-white;
    }

    [type="search"] {
      @apply bg-white/20 text-white;
    }

    :deep(.navigation--main) {
      nav > div {
        > span {
          @apply lg:text-white;
        }

        &:hover {
          > span > span {
            @apply border-white;
          }
        }
      }
    }
  }

  :deep(.logo svg) {
    // @apply h-5;
    @apply w-[100%];
  }

  .search {
    :deep(svg) {
      @apply h-4 w-4;
    }
  }
}
</style>
