<script lang="ts" setup>
import type { EntryCollection, EntrySys } from "contentful";
import type {
  TypeShareholderResolutionSkeleton,
  TypeTableResolutions,
} from "~/types/contentful";

const props = defineProps<{
  fields: TypeTableResolutions<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
  sys: EntrySys;
}>();

const route = useRoute();

const input = ref("");
const currentPage = ref(Number(useRoute().query[`page_${props.sys.id}`]) || 1);
const perPage = 18;
const container = ref<ComponentPublicInstance>();

const { data: resolutions } = await useLazyFetch<
  EntryCollection<
    TypeShareholderResolutionSkeleton,
    "WITHOUT_UNRESOLVABLE_LINKS",
    "en-GB"
  >
>("/api/shareholder-resolutions", {
  query: {
    input,
    page: computed(() => Number(useRoute().query[`page_${props.sys.id}`]) || 1),
    perPage,
  },
});

// Compute pagination total based on whether resolutions are filtered or not
const paginationTotal = computed(() => resolutions.value?.total ?? 0);

// Watch for changes in search input and reset current page to 1
watch(input, () => (currentPage.value = 1));

// Watch for changes in current page and scroll into view
watch(currentPage, async (page) => {
  container.value?.$el.scrollIntoView({
    behavior: "smooth",
    block: "start",
    inline: "nearest",
  });

  await navigateTo({
    hash: route.hash,
    query: { [`page_${props.sys.id}`]: page },
  });
});
</script>

<template>
  <Container
    ref="container"
    class="py-3 md:py-5"
  >
    <div
      v-if="fields.displaySectionTitle"
      class="w-full"
    >
      <TextSectionTitle>{{ fields.title }}</TextSectionTitle>
    </div>
    <div class="mt-4 flex flex-col justify-between md:flex-row">
      <div
        class="mb-3 flex h-5 w-full items-center rounded-sm border border-ui-grey2 md:mb-0 md:w-[70%] md:max-w-[700px]"
      >
        <NuxtIcon
          class="mx-2 w-4"
          name="search"
        />
        <input
          v-model="input"
          type="text"
          placeholder="Search for..."
        />
      </div>
    </div>

    <table
      class="mt-4 w-full border-separate border-spacing-1"
      aria-label="Resolutions"
    >
      <thead>
        <tr>
          <td class="text-tag bg-dark-blue-grey-two/10 p-2">
            Company
          </td>
          <td class="text-tag bg-dark-blue-grey-two/10 p-2">
            Resolution Name
          </td>
          <td class="text-tag bg-dark-blue-grey-two/10 p-2">
            Issue
          </td>
          <td class="text-tag bg-dark-blue-grey-two/10 p-2">
            Status
          </td>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="(resolution, index) in resolutions?.items"
          :key="index"
        >
          <td class="p-2">
            {{
              resolution.fields.targetCompany
                ? resolution.fields.targetCompany
                : ""
            }}
          </td>
          <td class="p-2">
            <NuxtLink
              :to="getUrl(resolution)"
              class="font-semibold"
            >
              {{ resolution.fields.title }}
            </NuxtLink>
          </td>
          <td class="p-2">
            {{ resolution.fields.issue }}
          </td>
          <td class="p-2">
            {{ resolution.fields.status }}
          </td>
        </tr>
      </tbody>
    </table>

    <ClientOnly>
      <vue-awesome-paginate
        v-if="paginationTotal > 12"
        v-model="currentPage"
        :total-items="paginationTotal"
        :items-per-page="perPage"
        :max-pages-shown="4"
        class="pagination-container"
        type="link"
        :link-url="`?page_${sys.id}=[page]${route.hash}`"
      >
        <template #prev-button>
          prev
        </template>
        <template #next-button>
          next
        </template>
      </vue-awesome-paginate>
    </ClientOnly>
  </Container>
</template>
