<script lang="ts" setup>
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import type { TypeBlockDoubleImagetext } from "~/types/contentful";
import type { TypeButton } from "~/types/TypeButton";

defineProps<{
  fields: TypeBlockDoubleImagetext<
    "WITHOUT_UNRESOLVABLE_LINKS",
    "en-GB"
  >["fields"] & { button1: TypeButton[]; button2: TypeButton[] };
}>();
</script>

<template>
  <Container class="block-image-text py-4 md:py-6">
    <div class="grid grid-cols-12 gap-4">
      <div
        v-if="fields.displaySectionTitle"
        class="col-span-12"
      >
        <TextSectionTitle>{{ fields.title }}</TextSectionTitle>
      </div>

      <div
        class="col-span-12 mb-4 text-center md:col-span-10 md:col-start-2 lg:col-span-6"
      >
        <div class="m-auto mb-4 w-3/4 pb-2">
          <NuxtImg
            v-if="fields.image1?.fields.file"
            class="m-auto"
            :src="fields.image1.fields.file.url"
            :alt="fields.image1.fields.title"
            :width="495"
            loading="lazy"
          />
        </div>

        <div
          v-if="fields.text1"
          class="wysiwyg mb-2"
        >
          <RichTextRenderer :document="fields.text1" />
        </div>
        <div v-if="fields.download1">
          <UiDownloadLink
            v-if="fields.download1"
            class-list="btn mt-3"
            :download="fields.download1"
          >
            {{ fields.download1.fields.shortTitle }}
            <template #locked>
              <NuxtLink
                class="btn mt-3"
                to="/login"
              >
                {{ fields.download1.fields.shortTitle }}
                <NuxtIcon
                  class="-mt-1 ms-2"
                  name="lock"
                />
              </NuxtLink>
            </template>
          </UiDownloadLink>
        </div>

        <template v-if="fields.button1">
          <div
            v-for="button in fields.button1"
            :key="button.url"
          >
            <NuxtLink
              v-if="button"
              :key="button.title"
              class="btn mt-3"
              :class="'btn--' + button.type"
              :to="button.url"
            >
              {{ button.title }}
            </NuxtLink>
          </div>
        </template>
      </div>

      <div
        class="col-span-12 text-center md:col-span-10 md:col-start-2 lg:col-span-6"
      >
        <div class="m-auto mb-4 w-3/4 pb-2">
          <NuxtImg
            v-if="fields.image2?.fields.file"
            class="m-auto"
            :src="fields.image2.fields.file.url"
            :alt="fields.image2.fields.title"
            :width="495"
            loading="lazy"
          />
        </div>

        <div
          v-if="fields.text2"
          class="wysiwyg mb-2"
        >
          <RichTextRenderer :document="fields.text2" />
        </div>

        <div v-if="fields.download2">
          <UiDownloadLink
            v-if="fields.download2"
            class-list="btn mt-3"
            :download="fields.download2"
          >
            {{ fields.download2.fields.shortTitle }}
            <template #locked>
              <NuxtLink
                class="btn mt-3"
                to="/login"
              >
                {{ fields.download2.fields.shortTitle }}
                <NuxtIcon
                  class="-mt-1 ms-2"
                  name="lock"
                />
              </NuxtLink>
            </template>
          </UiDownloadLink>
        </div>

        <template v-if="fields.button2">
          <div
            v-for="button in fields.button2"
            :key="button.url"
          >
            <NuxtLink
              v-if="button"
              :key="button.title"
              class="btn mt-3"
              :class="'btn--' + button.type"
              :to="button.url"
            >
              {{ button.title }}
            </NuxtLink>
          </div>
        </template>
      </div>
    </div>
  </Container>
</template>
