const redirects: { [0]: RegExp; [1]: string }[] = [
  // Network Members
  [
    /^\/about-fairr\/network-members\/Dignity Health/,
    "/member/dignity-health/",
  ],

  // Index
  [
    /^\/(coller-fairr-)?protein-producer-index/,
    "/tools/protein-producer-index",
  ],
  [/^\/index\/data.*$/, "/tools/protein-producer-index#company-ranking"],
  [
    /^\/index\/risk-factors-explained/,
    "/tools/protein-producer-index#esg-factors",
  ],
  [
    /^\/index\/risk(-opportunity)?-factors/,
    "/tools/protein-producer-index#key-findings",
  ],
  [/^\/index\/summary-of-findings$/, "/tools/protein-producer-index"],
  [/^\/index\/terms-conditions$/, "/terms-and-conditions"],
  [/^\/index\/the-fairr-initiative$/, "/about"],
  [/^\/index\/admin$/, "/"],
  [/^\/index\/logout$/, "/"],
  [/^\/index\/key-findings/, "/tools/protein-producer-index#key-findings"],
  [/^\/index\/best-practice\//, "/tools/protein-producer-index#methodology"],
  [/^\/index\/methodology/, "/tools/protein-producer-index#methodology"],
  [/^\/index\/index-in-action/, "/tools/protein-producer-index"],
  [
    /^\/index\/company-ranking/,
    "/tools/protein-producer-index#company-ranking",
  ],
  [
    /^\/index\/company-dialogue-questions/,
    "/tools/protein-producer-index#company-dialogue",
  ],
  [/^\/index\/reports-resources/, "/tools/protein-producer-index#downloads"],
  [
    /^\/index\/esg-factors\/greenhouse-gases-esg-factor/,
    "/tools/protein-producer-index#esg-factors",
  ],
  [
    /^\/index\/esg-factors\/deforestation-biodiversity-esg-factor/,
    "/tools/protein-producer-index#esg-factors/deforestation",
  ],
  [
    /^\/index\/esg-factors\/water-use-scarcity-esg-factor/,
    "/tools/protein-producer-index#esg-factors/water-use",
  ],
  [
    /^\/index\/esg-factors\/waste-pollution-esg-factor/,
    "/tools/protein-producer-index#esg-factors/waste-pollution",
  ],
  [
    /^\/index\/esg-factors\/antibiotics-esg-factor/,
    "/tools/protein-producer-index#esg-factors/antibiotics",
  ],
  [
    /^\/index\/esg-factors\/animal-welfare-esg-factor/,
    "/tools/protein-producer-index#esg-factors/animal-welfare",
  ],
  [
    /^\/index\/esg-factors\/working-conditions-esg-factor/,
    "/tools/protein-producer-index#esg-factors/working-conditions",
  ],
  [
    /^\/index\/esg-factors\/food-safety-esg-factor/,
    "/tools/protein-producer-index#esg-factors/food-safety",
  ],
  [
    /^\/index\/esg-factors\/governance-esg-factor/,
    "/tools/protein-producer-index#esg-factors/governance",
  ],
  [
    /^\/index\/esg-factors\/alternative-proteins-esg-factor/,
    "/tools/protein-producer-index#esg-factors/alternative-proteins",
  ],
  [
    /^\/index\/spotlight\/animal-waste-spotlight/,
    "/resources/reports/index-2021-how-animal-waste-mismanagement-drives-biodiversity-loss-and-accelerates-climate-risk",
  ],
  [
    /^\/index\/spotlight\/climate-risk-spotlight/,
    "/resources/reports/index-2021-managing-climate-risk-in-animal-agriculture",
  ],
  [
    /^\/index\/spotlight\/dairy-carbon-pricing/,
    "/resources/reports/the-impact-of-carbon-pricing-on-the-dairy-sector",
  ],
  [/^\/index\/spotlight/, "/tools/protein-producer-index"],
  [/^\/index/i, "/tools/protein-producer-index"],

  // Sustainable Proteins
  [
    /^\/article\/alternative-protein-market\//,
    "/resources/reports/climate-transition-proteins-flavour-future",
  ],
  [
    /^\/sustainable-proteins\/case-studies\/industry-start-ups(.*)$/,
    "/themes/alternative-proteins/food-technology/interviews$1",
  ],
  [
    /^\/sustainable-proteins\/case-studies\/corporate-best-practice(.*)$/,
    "/engagements/sustainable-proteins#outcomes",
  ],
  [
    /^\/sustainable-proteins\/engagement-overview\/about-the-engagement/,
    "/engagements/sustainable-proteins",
  ],
  [
    /^\/sustainable-proteins\/engagement-overview\/why-protein-diversification/,
    "/engagements/protein-diversification",
  ],
  [
    /^\/sustainable-proteins\/engagement-overview\/regulation/,
    "/policy/regulations/alternative-proteins/",
  ],
  [
    /^\/sustainable-proteins\/engagement-overview\/market-update/,
    "/engagements/sustainable-proteins",
  ],
  [
    /^\/sustainable-proteins\/summary-of-findings/,
    "/engagements/sustainable-proteins#outcomes",
  ],
  [
    /^\/sustainable-proteins\/methodology/,
    "/engagements/sustainable-proteins#methodology",
  ],
  [
    /^\/sustainable-proteins\/progress-update\/methodology/,
    "/engagements/sustainable-proteins#methodology",
  ],
  [
    /^\/sustainable-proteins\/food-tech-spotlight\/industry-start-ups(.*)$/,
    "/themes/alternative-proteins/food-technology/interviews$1",
  ],
  [
    /^\/sustainable-proteins\/food-tech-spotlight\/brf-bringing-cultivated-beef-to-brazilian-market-by-2024/,
    "/themes/alternative-proteins/food-technology/interviews/brf",
  ],
  [
    /^\/sustainable-proteins\/company-ranking/,
    "/engagements/sustainable-proteins#company-ranking",
  ],
  [
    /^\/sustainable-proteins\/progress-update\/about-the-engagement/,
    "/engagements/sustainable-proteins",
  ],
  [
    /^\/sustainable-proteins\/progress-update\/why-protein-diversification/,
    "/engagements/protein-diversification",
  ],
  [
    /^\/sustainable-proteins\/progress-update\/summary-of-findings/,
    "/engagements/sustainable-proteins#outcomes",
  ],
  [
    /^\/sustainable-proteins\/progress-update/,
    "/engagements/sustainable-proteins",
  ],
  [
    /^\/sustainable-proteins\/resource-library/,
    "/engagements/sustainable-proteins#downloads",
  ],
  [
    /^\/sustainable-proteins\/food-tech-spotlight\/interviews/,
    "/themes/alternative-proteins/food-technology/interviews",
  ],
  [
    /^\/sustainable-proteins\/food-tech-spotlight\/building-esg-into-food-tech/,
    "/resources/reports/climate-transition-proteins-flavour-future",
  ],
  [
    /^\/sustainable-proteins\/food-tech-spotlight\/venture-investments/,
    "/themes/alternative-proteins/food-technology/venture-investments",
  ],
  [
    /^\/sustainable-proteins\/food-tech-spotlight\/climate-transition-proteins/,
    "/engagements/protein-diversification",
  ],
  [
    /^\/sustainable-proteins\/food-tech-spotlight/,
    "/themes/alternative-proteins/food-technology",
  ],
  [
    /^\/sustainable-proteins\/policy-regulation/,
    "/policy/regulations/alternative-proteins",
  ],
  [/^\/sustainable-proteins/, "/engagements/sustainable-proteins"],

  // CRT
  [
    /^(\/research\/climate-risk|\/crt|\/climaterisktool|\/climate-risk-tool|\/tools\/coller-fairr-climate-risk)/,
    "/tools/climate-risk-tool",
  ],
  [
    /^\/tools\/climate-risk-tool\/company\/(.+?)\/?$/,
    "/company/$1/climate-risk-tool",
  ],
  [
    /^\/tools\/climate-risk-tool\/company\/(.+)/,
    "/resources/companies-assessed/$1",
  ],

  // Animal Pharma
  [
    /^\/engagements?\/animal-pharma\/company\/(.+?)\/?$/,
    "/company/$1/animal-pharma",
  ],

  // Waste pollution
  [
    /^\/engagements?\/waste-pollution\/company\/(.+?)\/?$/,
    "/company/$1/waste-pollution",
  ],
  [/^\/engagements\/biodiversity/, "/engagements/waste-pollution"],

  // Antibiotics Stewardship
  [
    /^\/engagements?\/antibiotics-engagement(.*)/,
    "/engagements/antibiotics-stewardship$1",
  ],

  // Old articles
  [/^\/article\/roadmap-to-2050/, "/investor-statements/roadmap-to-2050"],
  [
    /^\/article\/g20-agricultural-subsidies-statement/,
    "/investor-statements/g20-agricultural-subsidies",
  ],
  [
    /^\/article\/sustainable-processing-innovations-2/,
    "/news-events/events/sustainable-processing-innovations-event",
  ],
  [
    /^\/article\/protein-pulse-19-25-may/,
    "/news-events/weekly-digest/the-protein-pulse-19-25-may",
  ],
  [
    /^\/article\/private-equity-veteran-wants-end-animal-factory-farming/,
    "/news-events/press-releases",
  ],
  [
    /^\/article\/looming-antibiotics-crisis-troubles-big-investors-financial-times-article/,
    "/news-events/press-releases",
  ],
  [
    /^\/article\/how-nestle-plans-to-win-foodservice-with-a-plant-based-triple-play/,
    "/news-events/press-releases",
  ],
  [
    /^\/article\/burger-king-to-launch-meatless-rebel-whopper-in-brazil/,
    "/news-events/press-releases",
  ],
  [
    /^\/article\/big-investors-push-mcdonalds-yum-and-dominos-to-drop-antibiotics-fortune-article/,
    "/news-events/press-releases",
  ],
  [
    /^\/article\/factory-farming-2-2/,
    "/resources/reports/factory-farming-assessing-investment-risks",
  ],
  [
    /^\/article\/rapport-de-synthese-indice-coller-fairr-protein-producer/,
    "/resources/reports/coller-fairr-protein-producer-index-2019-latam-edition",
  ],
  [
    /^\/article\/coller-fairr-protein-producer-index-2019-asian-edition-chinese/,
    "/resources/reports/coller-fairr-protein-producer-index-2019-asian-edition",
  ],
  [
    /^\/article\/coller-fairr-protein-producer-index-2019-latam-edition-portuguese/,
    "/article/coller-fairr-protein-producer-index-2019-latam-edition",
  ],
  [
    /^\/article\/transforming-animal-agriculture-chinese/,
    "/resources/reports/transforming-animal-agriculture",
  ],
  [/^\/article\/report(.*)/, "/resources/reports$1"],
  [/^\/article\/engagement-update(.*)/, "/resources/reports$1"],
  [
    /^\/48-trillion-investor-group-calls-for-urgent-action-on-feed-alternatives/,
    "/news-events/press-releases",
  ],

  // Old companies
  [
    /^\/company\/coles-supermarket(.*)/,
    "/resources/companies-assessed/coles-group$1",
  ],
  [
    /^\/company\/darling-ingredients(.*)/,
    "/resources/companies-assessed/darling-ingredients-inc$1",
  ],
  [
    /^\/company\/grupo-nutresa-sa-2(.*)/,
    "/resources/companies-assessed/grupo-nutresa-s-a",
  ],
  [
    /^\/company\/marks-spencer(.*)/,
    "/resources/companies-assessed/marks-and-spencer$1",
  ],
  [
    /^\/company\/mitchells-butlers(.*)/,
    "/resources/companies-assessed/mitchells-and-butlers$1",
  ],
  [
    /^\/company\/yara-international(.*)/,
    "/resources/companies-assessed/yara-international-asa$1",
  ],
  [
    /^\/company\/nippon-suisan-kaisha-ltd-2(.*)/,
    "/resources/companies-assessed/nissui-corporation$1",
  ],
  [
    /^\/company\/chuying-agro-pastoral-group-co-ltd-2(.*)/,
    "/resources/companies-assessed",
  ],
  [/^\/company\/empresas-aquachile-sa-2(.*)/, "/resources/companies-assessed"],
  [/^\/company\/qaf-ltd-2(.*)/, "/resources/companies-assessed"],
  [/^\/company\/ocado(.*)/, "/resources/companies-assessed"],
  [/^\/company\/(.+)-2(\/.+)?/, "/resources/companies-assessed/$1$2"],
  [/^\/company\/(.+)(\/.+)?/, "/resources/companies-assessed/$1$2"],

  // Old authors
  [/^\/author\/katezabolonskaya/, "/about/our-team/ekaterina-zabolonskaya"],

  // Accounts
  [/^\/reset-password\/(.+)$/, "/set-password$1"],

  // New 2023 website
  [/^\/engagements?\/(.+)-engagement(.*)/, "/engagements/$1$2"],
  [/^\/engagements?\/(.+)\/.+-companies/, "/engagements/$1#company-data"],
  [/^\/engagements\/amazon-soy-moratorium/, "/investor-statements/amazon-soy"],
  [
    /^\/engagements\/global-investor-engagement-meat-sourcing/,
    "/engagements/meat-sourcing",
  ],
  [/^\/research\/reports\/engagement-updates/, "/resources/reports"],
  [/^\/research\/reports/, "/resources/reports"],
  [/^\/research\/fairr-company-universe/, "/resources/companies-assessed"],
  [/^\/research\/animal-pharma/, "/engagements/animal-pharma"],
  [/^\/research\/animal-health/, "/resources/reports/feeding-resistance"],
  [/^\/research(.*)/, "/tools$1"],
  [/^\/policy\/policy-consultations/, "/policy/consultations"],
  [/^\/policy\/cop2([67])-highlights/, "/policy/cop/cop2$1-highlights"],
  [
    /^\/policy\/highlights-of-fairrs-activities-at-cop26/,
    "/policy/cop/cop26-highlights",
  ],
  [/^\/about-fairr\/knowledge-hub(.*)/, "/resources/knowledge-hub$1"],
  [
    /^\/regenerative-agriculture/,
    "/themes/biodiversity/regenetive-agriculture",
  ],
  [/^\/news-events\/blogs/, "/news-events/insights"],
  [/^\/news-events\/news-hub/, "/news-events/weekly-digest"],
  [/^\/region\/.+/, "/news-events"],
  [/^\/our-plans/, "/about/story-vision"],
  [/^\/about-fairr\/our-team/, "/about/our-team"],
  [/^\/staff(.+)/, "/about/our-team$1"],
  [/^\/about-fairr\/network-members/, "/about/network-members"],
  [/^\/about-fairr\/about-us\/case-studies/, "/about/case-studies"],
  [/^\/about-fairr\/our-team\/jobs/, "/about/working-at-fairr"],
  [/^\/about-fairr.*/, "/about"],
  [/^\/register/, "/signup"],
  [/^\/access/, "/signup"],
  [/^\/individual-members/, "/signup"],
  [/^\/registration-success/, "/email-confirmed"],
  [/^\/thank-you-received/, "/email-confirmed"],
  [/^\/thank-you/, "/email-confirmed"],
  [/^\/email-confirmed-newsletter-subscription/, "/email-confirmed"],
  [/^\/checkout/, "/"],
  [/^\/investor-membership/, "/terms-reference"],
  [/^\/job.+/, "/about/working-at-fairr"],
  [/^\/topic\/animal-welfare/, "/themes/antibiotics-health"],
  [/^\/topic\/deforestation-biodiversity-loss/, "/themes/biodiversity"],
  [/^\/topic\/food-safety/, "/themes/antibiotics-health"],
  [/^\/topic\/greenhouse-gas-emissions/, "/themes/climate-risk"],
  [/^\/topic\/waste-pollution/, "/themes/biodiversity"],
  [/^\/topic\/water-scarcity-use/, "/themes/climate-risk"],
  [/^\/type\/blog/, "/news-events/insights"],
  [/^\/type\/case-study/, "/about/case-studies"],
  [
    /^\/type\/dairy-production-risks/,
    "/resources/knowledge-hub/production-risks",
  ],
  [/^\/type\/fairr-conversations-podcasts/, "/news-events/podcasts"],
  [/^\/type\/food-scandals/, "/resources/knowledge-hub/food-scandals"],
  [/^\/type\/guest-podcast/, "/news-events/podcasts"],
  [/^\/type\/key-terms/, "/resources/knowledge-hub/key-terms"],
  [/^\/type\/knowledge-hub/, "/resources/knowledge-hub"],
  [
    /^\/type\/intensive-farming-pollution/,
    "/resources/knowledge-hub/intensive-farming-pollution",
  ],
  [/^\/type\/investor-letter/, "/investor-statements"],
  [/^\/type\/issue-briefing/, "/policy/issue-briefings"],
  [
    /^\/type\/meat-production-risks/,
    "/resources/knowledge-hub/production-risks",
  ],
  [/^\/type\/press-coverage/, "/news-events"],
  [/^\/type\/press-release/, "/news-events/press-releases"],
  [/^\/type\/report-push/, "/resources/reports"],
  [/^\/type\/report/, "/resources/reports"],
  [/^\/type\/sector-news/, "/news-events"],
  [/^\/request/, "/news-events/media-centre"],
  [/^\/resource\/fairr-action-case-studies-volume-iii/, "/about/case-studies"],

  // Knowledge Hub
  [/^\/resources\/knowledge-hub\/key-terms\/clean-meat/, "/news-events/insights/esg-risks-and-opportunities-of-clean-meat"],
  [/^\/resources\/knowledge-hub\/food-scandals\/2001-foot-and-mouth-outbreak/, "/news-events/insights/uk-foot-and-mouth-outbreak-2001"],
  [/^\/resources\/knowledge-hub\/food-scandals\/2018-african-swine-fever-outbreak/, "/news-events/insights/china-african-swine-fever-outbreak-2018"],
  [/^\/resources\/knowledge-hub\/production-risks\/meat-production-risks/, "/news-events/insights/health-sustainability-risks-of-meat-production"],
  [/^\/resources\/knowledge-hub\/production-risks\/dairy-production-risks/, "/news-events/insights/material-sustainability-risks-dairy-farming"],
  [/^\/resources\/knowledge-hub\/food-scandals\/2013-horse-meat/, "/news-events/insights/europe-horse-meat-scandal-2013"],
  [/^\/resources\/knowledge-hub\/key-terms\/meat-tax/, "/news-events/insights/meat-tax"],
  [/^\/resources\/knowledge-hub\/key-terms\/costs-of-factory-farming/, "/news-events/insights/factory-farming-unveiling-the-hidden-risks-for-investors"],
  [/^\/resources\/knowledge-hub\/key-terms\/responsible-investment-esg-and-the-food-sector/, "/news-events/insights/risks-and-opportunities-in-the-food-sector"],
  [/^\/resources\/knowledge-hub\/key-terms\/intensive-factory-farming/, "/news-events/insights/intensive-animal-agriculture"],
  [/^\/resources\/knowledge-hub\/key-terms\/cerrado-deforestation/, "/news-events/insights/cerrado-deforestation"],

  // No underscores
  [/_/g, "-"],

  // Old WordPress
  [/^\/wp-.*/, "/"],

  // Set password token (so that we don't need a URL param in the email body)
  [/^\/set-password\/(.+)/, "/set-password?token=$1"],

  [/(.+)\/+$/, "$1"],
];

const tempRedirects: { [0]: RegExp; [1]: string }[] = [
  [/^\/dashboard/, "/"],
  [/^\/aim4c/, "/news-events/events/aim4c-summit"],
];

export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.client) {
    return;
  }

  for (const redirect of tempRedirects) {
    if (to.path.match(redirect[0])) {
      return navigateTo(
        to.path.replace(redirect[0], redirect[1]).replace(/\/+/, "/"),
        {
          redirectCode: 307,
        },
      );
    }
  }

  for (const redirect of redirects) {
    if (to.path.match(redirect[0])) {
      return navigateTo(
        to.path.replace(redirect[0], redirect[1]).replace(/\/+/, "/"),
        {
          redirectCode: 301,
        },
      );
    }
  }
});
