<template>
  <div class="section-title relative mb-0 sm:mb-2 md:mb-4">
    <h2 class="relative top-[-14px] mb-0 pe-4 md:top-[-6px]">
      <slot />
    </h2>
  </div>
</template>

<style lang="scss" scoped>
.section-title {
  &::before {
    content: "";
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 14px;
    background: linear-gradient(
      to bottom,
      #e7e7eb 0 2px,
      white 2px 6px,
      #e7e7eb 6px 8px,
      white 8px 12px,
      #e7e7eb 12px 14px
    );

    @apply bottom-[initial] md:top-[calc(50%-7px)];
  }

  h2 {
    background-color: white;
    width: fit-content;
  }
}
</style>
