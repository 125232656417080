<script lang="ts" setup>
import type { TypeBlockAccordion } from "~/types/contentful";
import type { TypeAccordionItem } from "~/types/TypeAccordionItem";

defineProps<{
  fields: TypeBlockAccordion<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
}>();

const showAnswers = reactive<
  Partial<{ [key in keyof TypeAccordionItem]: boolean }>
>({});
</script>

<template>
  <Container class="block-image-text py-4 md:py-6">
    <div
      v-if="fields.displaySectionTitle"
      class="col-span-12"
    >
      <TextSectionTitle>{{ fields.title }}</TextSectionTitle>
    </div>

    <div
      v-for="item in fields.accordionItem as unknown as TypeAccordionItem[]"
      :key="item.title"
      class="accordion-item mb-3 cursor-pointer select-none overflow-hidden rounded bg-level-standard p-3"
      @click="
        showAnswers[item.title as keyof TypeAccordionItem]
          = !showAnswers[item.title as keyof TypeAccordionItem]
      "
    >
      <div>
        <h4 class="mb-0 flex justify-between">
          {{ item.title }}
          <div
            class="ms-3 w-[27px] min-w-[27px]"
            :class="{
              open: showAnswers[item.title as keyof TypeAccordionItem],
            }"
          >
            <NuxtIcon
              name="arrow"
              :class="{
                'rotate-180':
                  showAnswers[item.title as keyof TypeAccordionItem],
              }"
            />
          </div>
        </h4>

        <div
          v-if="showAnswers[item.title as keyof TypeAccordionItem]"
          class="mt-3 border-t border-ui-grey2 pt-3 leading-6"
        >
          {{ item.copy }}
        </div>
      </div>
    </div>
  </Container>
</template>
