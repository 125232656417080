<script lang="ts" setup>
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import type { TypeBannerPromo } from "~/types/contentful";

defineProps<{
  fields: TypeBannerPromo<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
}>();
</script>

<template>
  <Container class="banner-promo py-4 md:py-6">
    <div
      class="background-wrapper rounded-sm px-3 py-5 before:left-0 sm:w-full md:py-6"
      :style="{
        backgroundImage: fields.image?.fields.file?.url
          ? `url(${fields.image.fields.file.url})`
          : undefined,
      }"
      :class="fields.image ? 'background-wrapper--image' : ''"
    >
      <div class="grid grid-cols-12 gap-4">
        <div
          class="col-span-12 text-center md:col-span-10 md:col-start-2 lg:col-span-6 lg:col-start-4"
        >
          <div
            v-if="fields.tag"
            class="tag-badge mb-3 inline-block bg-white/20"
          >
            {{ fields.tag }}
          </div>
          <h2 class="mb-3">
            {{ fields.title }}
          </h2>
          <RichTextRenderer :document="fields.text" />
          <NuxtLink
            :to="fields.buttonLink"
            target="_self"
            class="btn mt-3"
          >
            {{
              fields.buttonCopy
            }}
          </NuxtLink>
        </div>
      </div>
      <div class="bottom after:absolute after:bottom-[-12px] after:left-0 after:h-[12px] after:w-full after:content-[''] sm:w-full" />
    </div>
  </Container>
</template>

<style lang="scss" scoped>
.banner-promo {
  .background-wrapper {
    @apply relative bg-dark-blue-grey-two bg-cover bg-center text-white;
    @apply before:md:absolute before:md:top-0 before:md:h-full before:md:w-[306px] before:md:bg-promo-bg before:md:content-[''];
    @apply after:md:absolute after:md:top-0 after:md:h-full after:md:w-[306px] after:md:bg-promo-bg after:md:content-[''];

    &--image {
      &::before {
        display: none;
      }

      &::after {
        display: none;
      }
    }

    &::after {
      right: 0;
      transform: rotate(180deg);
    }
  }

  &:deep(a) {
    color: white;
  }

  .bottom {
    &::after {
      background: linear-gradient(
        to bottom,
        theme("colors.white") 0 2px,
        theme("colors.dark-blue-grey-two") 2px 4px,
        theme("colors.white") 4px 6px,
        theme("colors.dark-blue-grey-two") 6px 8px,
        theme("colors.white") 8px 10px,
        theme("colors.dark-blue-grey-two") 10px 12px
      );
    }
  }
}
</style>
