import { default as _91_46_46_46slug_93EhWyQvnGPmMeta } from "/opt/build/repo/pages/[...slug].vue?macro=true";
import { default as _91slug_93A8syaJwCejMeta } from "/opt/build/repo/pages/about/our-team/[slug].vue?macro=true";
import { default as reset_45password4iBG212Ge4Meta } from "/opt/build/repo/pages/reset-password.vue?macro=true";
import { default as animal_45pharmaji4y7ANq2JMeta } from "/opt/build/repo/pages/resources/companies-assessed/[name]/animal-pharma.vue?macro=true";
import { default as antibiotics_45stewardshipXgkvxj8l1vMeta } from "/opt/build/repo/pages/resources/companies-assessed/[name]/antibiotics-stewardship.vue?macro=true";
import { default as climate_45risk_45toolYIW3F9XSq9Meta } from "/opt/build/repo/pages/resources/companies-assessed/[name]/climate-risk-tool.vue?macro=true";
import { default as downloadsgSd2JHIZwQMeta } from "/opt/build/repo/pages/resources/companies-assessed/[name]/downloads.vue?macro=true";
import { default as index5WxZXBBalhMeta } from "/opt/build/repo/pages/resources/companies-assessed/[name]/index.vue?macro=true";
import { default as meat_45sourcingzdIuxRZdh1Meta } from "/opt/build/repo/pages/resources/companies-assessed/[name]/meat-sourcing.vue?macro=true";
import { default as printyWgqBajwzdMeta } from "/opt/build/repo/pages/resources/companies-assessed/[name]/print.vue?macro=true";
import { default as protein_45diversificationjhrsna134AMeta } from "/opt/build/repo/pages/resources/companies-assessed/[name]/protein-diversification.vue?macro=true";
import { default as protein_45producer_45indexhW5t5r7J0eMeta } from "/opt/build/repo/pages/resources/companies-assessed/[name]/protein-producer-index.vue?macro=true";
import { default as restaurant_45antibioticsnQv07uyCA7Meta } from "/opt/build/repo/pages/resources/companies-assessed/[name]/restaurant-antibiotics.vue?macro=true";
import { default as sustainable_45proteinso40G3T6OYjMeta } from "/opt/build/repo/pages/resources/companies-assessed/[name]/sustainable-proteins.vue?macro=true";
import { default as waste_45pollutionFoatqvYp5sMeta } from "/opt/build/repo/pages/resources/companies-assessed/[name]/waste-pollution.vue?macro=true";
import { default as working_45conditionsBDWXByoAktMeta } from "/opt/build/repo/pages/resources/companies-assessed/[name]/working-conditions.vue?macro=true";
import { default as _91name_93pM1worgYN9Meta } from "/opt/build/repo/pages/resources/companies-assessed/[name].vue?macro=true";
import { default as searchi1lVFWwv2QMeta } from "/opt/build/repo/pages/search.vue?macro=true";
import { default as set_45passwordKLRgnMCIcZMeta } from "/opt/build/repo/pages/set-password.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/opt/build/repo/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "about-our-team-slug",
    path: "/about/our-team/:slug()",
    component: () => import("/opt/build/repo/pages/about/our-team/[slug].vue").then(m => m.default || m)
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45password4iBG212Ge4Meta || {},
    component: () => import("/opt/build/repo/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: _91name_93pM1worgYN9Meta?.name,
    path: "/resources/companies-assessed/:name()",
    component: () => import("/opt/build/repo/pages/resources/companies-assessed/[name].vue").then(m => m.default || m),
    children: [
  {
    name: "resources-companies-assessed-name-animal-pharma",
    path: "animal-pharma",
    component: () => import("/opt/build/repo/pages/resources/companies-assessed/[name]/animal-pharma.vue").then(m => m.default || m)
  },
  {
    name: "resources-companies-assessed-name-antibiotics-stewardship",
    path: "antibiotics-stewardship",
    component: () => import("/opt/build/repo/pages/resources/companies-assessed/[name]/antibiotics-stewardship.vue").then(m => m.default || m)
  },
  {
    name: "resources-companies-assessed-name-climate-risk-tool",
    path: "climate-risk-tool",
    component: () => import("/opt/build/repo/pages/resources/companies-assessed/[name]/climate-risk-tool.vue").then(m => m.default || m)
  },
  {
    name: "resources-companies-assessed-name-downloads",
    path: "downloads",
    component: () => import("/opt/build/repo/pages/resources/companies-assessed/[name]/downloads.vue").then(m => m.default || m)
  },
  {
    name: "resources-companies-assessed-name",
    path: "",
    component: () => import("/opt/build/repo/pages/resources/companies-assessed/[name]/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-companies-assessed-name-meat-sourcing",
    path: "meat-sourcing",
    component: () => import("/opt/build/repo/pages/resources/companies-assessed/[name]/meat-sourcing.vue").then(m => m.default || m)
  },
  {
    name: "resources-companies-assessed-name-print",
    path: "print",
    meta: printyWgqBajwzdMeta || {},
    component: () => import("/opt/build/repo/pages/resources/companies-assessed/[name]/print.vue").then(m => m.default || m)
  },
  {
    name: "resources-companies-assessed-name-protein-diversification",
    path: "protein-diversification",
    component: () => import("/opt/build/repo/pages/resources/companies-assessed/[name]/protein-diversification.vue").then(m => m.default || m)
  },
  {
    name: "resources-companies-assessed-name-protein-producer-index",
    path: "protein-producer-index",
    component: () => import("/opt/build/repo/pages/resources/companies-assessed/[name]/protein-producer-index.vue").then(m => m.default || m)
  },
  {
    name: "resources-companies-assessed-name-restaurant-antibiotics",
    path: "restaurant-antibiotics",
    component: () => import("/opt/build/repo/pages/resources/companies-assessed/[name]/restaurant-antibiotics.vue").then(m => m.default || m)
  },
  {
    name: "resources-companies-assessed-name-sustainable-proteins",
    path: "sustainable-proteins",
    component: () => import("/opt/build/repo/pages/resources/companies-assessed/[name]/sustainable-proteins.vue").then(m => m.default || m)
  },
  {
    name: "resources-companies-assessed-name-waste-pollution",
    path: "waste-pollution",
    component: () => import("/opt/build/repo/pages/resources/companies-assessed/[name]/waste-pollution.vue").then(m => m.default || m)
  },
  {
    name: "resources-companies-assessed-name-working-conditions",
    path: "working-conditions",
    component: () => import("/opt/build/repo/pages/resources/companies-assessed/[name]/working-conditions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/opt/build/repo/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "set-password",
    path: "/set-password",
    meta: set_45passwordKLRgnMCIcZMeta || {},
    component: () => import("/opt/build/repo/pages/set-password.vue").then(m => m.default || m)
  }
]