import revive_payload_client_4sVQNw7RlN from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/opt/build/repo/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_Fdvg3o8568 from "/opt/build/repo/node_modules/@nuxtjs/web-vitals/dist/runtime/plugin.client.mjs";
import plugin_tbFNToZNim from "/opt/build/repo/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import click_outside_D2NiLGp7dJ from "/opt/build/repo/plugins/click-outside.ts";
import router_scroll_client_ba1lAMh9cb from "/opt/build/repo/plugins/router-scroll.client.ts";
import swiper_client_blbPZy93VS from "/opt/build/repo/plugins/swiper.client.ts";
import v_tooltip_J4SXZrA1Eq from "/opt/build/repo/plugins/v-tooltip.ts";
import vfm_AT9bKzcFx9 from "/opt/build/repo/plugins/vfm.ts";
import vue_awesome_paginate_client_SKBrb04POb from "/opt/build/repo/plugins/vue-awesome-paginate.client.ts";
import vue3_touch_events_client_ia14fW7uF5 from "/opt/build/repo/plugins/vue3-touch-events.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_Fdvg3o8568,
  plugin_tbFNToZNim,
  click_outside_D2NiLGp7dJ,
  router_scroll_client_ba1lAMh9cb,
  swiper_client_blbPZy93VS,
  v_tooltip_J4SXZrA1Eq,
  vfm_AT9bKzcFx9,
  vue_awesome_paginate_client_SKBrb04POb,
  vue3_touch_events_client_ia14fW7uF5
]